import { useEffect, useState } from 'react';
import { messagesInEnglish } from 'languages/en';
import { messagesInPortuguese } from 'languages/br';
import { messagesInJapanese } from 'languages/jp';

const LocalizatedMessage = (value) => {
    const [len, setLen] = useState(localStorage.getItem('leng') || 'en')

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setLen(localStorage.getItem('leng') || 'en')
    });

    const lenAux = {
        en: messagesInEnglish,
        jp: messagesInJapanese,
        br: messagesInPortuguese
    }
    
    return lenAux[len][value]
}

export default LocalizatedMessage;