import styled from 'styled-components'

import { theme } from 'configs/theme'

import misadCoin from 'assets/misadCoin.png'

const Spinner = ({ variant = 'big' }) => {
  return (
    <Wrapper>

      <div class="loading">
        <div class="d1"></div>
        <div class="d2"></div>
      </div>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const BaseContainer = styled.div`
  position: relative;
  width: ${({ variant }) => (variant === 'big' ? '150px' : '100px')};
  height: ${({ variant }) => (variant === 'big' ? '135px' : '85px')};
`
const Base = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 50%;
  border-top: ${({ color, variant }) => `${variant === 'big' ? '3px' : '2px'} solid ${color}`};
  animation: ${({ speed }) => `spinnerLoader ${speed} infinite ease`};
  @keyframes spinnerLoader {
    to {
      transform: rotate(360deg);
    }
  }
`
const LogoContainer = styled.div`
  position: absolute;
  top: 15.5px;
  left: 15.5px;
`

export default Spinner
