import { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import icon_4 from 'assets/icon_4.png'
import Dropdown from 'react-bootstrap/Dropdown';
import LocalizatedMessage from 'localization';

export default function SelectLanguage() {
  const [leng, setLeng] = useState('en')

  const english = LocalizatedMessage('english')
  const portuguese = LocalizatedMessage('portuguese')
  const japanese = LocalizatedMessage('japanese')

  useEffect(() => {
    const getLen = localStorage.getItem('leng')
    setLeng(getLen)
  }, [])

  const handleChange = (value) => {
    localStorage.setItem('leng', value)
    setLeng(value)
    window.location.reload(true);
  }

  return (
    <>
      <Dropdown className="dropdown-language" onSelect={handleChange}>
        <Dropdown.Toggle variant="dark" id="dropdown-basic">
          <img src={icon_4} />
        </Dropdown.Toggle>

        <Dropdown.Menu variant="dark">
          <Dropdown.Item eventKey="en">{english}</Dropdown.Item>
          <Dropdown.Item eventKey="br">{portuguese}</Dropdown.Item>
          <Dropdown.Item eventKey="jp">{japanese}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}