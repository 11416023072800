export const endpointUrl = process.env.REACT_APP_WEBSERVICE_ENDPOINT;
export const dappUrl = process.env.REACT_APP_ENVIRONMENT_ENDPOINT;

export const endpoints = {
    login: "api/v1/authentication/login",
    nftlist: "api/v1/token",
    nftPhoto: "api/v1/token/image",
    nftData: "api/v1/token",
    nftOpen: "api/v1/token/open",
    landsNftlist: "api/v1/lands",
    landsNftPhoto: "api/v1/lands/image",
    landsNftData: "api/v1/lands",
    landsNftOpen: "api/v1/lands/open",
    soulsNftlist: "api/v1/souls",
    soulsNftPhoto: "api/v1/souls/image",
    soulsNftData: "api/v1/souls",
    soulsNftOpen: "api/v1/souls/open",
    stake: "api/v1/stake/nfts",
    stakeCost: "api/v1/transaction/data/bep721",
    remnantWithdrawStake: "api/v1/token/stake/withdraw",
    remnantStake: "api/v1/token/stake",
    landStake: "api/v1/lands/stake",
    healthcheck: "api/v1/system/healthcheck",
    gascost: "api/v1/transaction/gascost",
    landWithdrawStake: "api/v1/lands/stake/withdraw",
    stakeDetails: "api/v1/misad",
    stakeWithdraw: "api/v1/misad/stake/withdraw",
    stakeLog: "api/v1/misad/log",
    nftMisadStakeDetails: "api/v1/misad/stake/nft",
    nftMisadStakeWithdraw: "api/v1/misad/stake/nft/withdraw",
    stakeWithdrawValidate: "api/v1/misad/stake/validate",
    nftStakeWithdrawValidate: "api/v1/misad/stake/nft/validate",
    stakeApr: "api/v1/stake/apr",
    transactionLog: "api/v1/misad/stake/transactions",
    stakeDeposit: "api/v1/misad/stake",
    stakeChangeLock: "api/v1/misad/stake/lock",
    restake: "api/v1/misad/stake/restake",
    rewardValidate: "api/v1/misad/stake/reward/validate",
    rewardWithdraw: "api/v1/misad/stake/reward/withdraw",
    restakeTax: "api/v1/misad/stake/restake/tax",
    tokenStore: "api/v1/token/store",
    tokenStoreAllowance: "api/v1/token/store/whitelist",
    remnantData: "api/remnant/v1",
    landData: "api/land/v1",
    soulData: "api/soul/v1",
    maginekoStore: "api/v1/magineko/store",
    maginekoStoreAllowance: "api/v1/magineko/store/whitelist",
    maginekoNftlist: "api/v1/magineko",
    maginekoNftOpen: "api/v1/magineko/open",
    maginekoData: "api/magineko/v1",
    equipmentNftlist: "api/v1/equipment",
    equipmentNftOpen: "api/v1/equipment/open",
    equipmentData: "api/equipment/v1",
    maginekoDetail: "api/v1/magineko",
    maginekoValidatePet: "api/v1/magineko/validate",
    maginekoPet: "api/v1/magineko/pet",
    walletBalance: "api/v1/wallet",
    validateWalletWithdraw: "api/v1/wallet/withdraw/validate",
    walletWithdraw: "api/v1/wallet/withdraw",
    randomMagineko: "api/v1/magineko/random",
    tokenStoreList: "api/v1/store",
    tokenStoreBuy: "api/v1/store/buy",
    validateOfferRegister: "api/v1/marketplace/validate",
    offerRegister: "api/v1/marketplace",
    remnantMarketplace: "api/v1/token/marketplace",
    landMarketplace: "api/v1/lands/marketplace",
    soulMarketplace: "api/v1/souls/marketplace",
    maginekoMarketplace: "api/v1/magineko/marketplace",
    equipmentMarketplace: "api/v1/equipment/marketplace",
    marketplacePurchaseValidate: "api/v1/marketplace/purchase/validate",
    marketplacePurchase: "api/v1/marketplace/purchase",
    marketplaceLog: "api/v1/marketplace/log",
    marketplaceValidateOfferCancel: "api/v1/marketplace/cancel/validate",
    marketplaceOfferCancel: "api/v1/marketplace/cancel",
    validateTokenStore: "api/v1/store/validate"
};
