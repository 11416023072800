import misadNftTestContract from 'contracts/misadNftTestContract'
import misadTestContract from 'contracts/misadTestContract'
import { contractAddressByType } from 'configs/contractAddress';

export const chainNet = process.env.REACT_APP_CHAIN_NET;
export const chainNetId = process.env.REACT_APP_CHAIN_NET_ID;
export const chainName = process.env.REACT_APP_CHAIN_NET_NAME;
export const chainRPC = process.env.REACT_APP_CHAIN_NET_RPC;
export const chainSymbol = "BNB";
export const chainExplorer = "https://explorer.binance.org/smart-testnet";

export const ethereumChainNet = process.env.REACT_APP_ETHEREUM_CHAIN_NET;
export const ethereumChainNetId = process.env.REACT_APP_ETHEREUM_CHAIN_NET_ID;
export const ethereumChainName = process.env.REACT_APP_ETHEREUM_CHAIN_NET_NAME;
export const ethereumChainRPC = process.env.REACT_APP_ETHEREUM_CHAIN_NET_RPC;
export const ethereumChainSymbol = "MATIC";
export const ethereumChainExplorer = "https://ethereumscan.com/";

export const klayChainNet = process.env.REACT_APP_KLAY_CHAIN_NET;
export const klayChainNetId = process.env.REACT_APP_KLAY_CHAIN_NET_ID;
export const klayChainName = process.env.REACT_APP_KLAY_CHAIN_NET_NAME;
export const klayChainRPC = process.env.REACT_APP_KLAY_CHAIN_NET_RPC;
export const klayChainSymbol = "KLAY";
export const klayChainExplorer = "https://scope.klaytn.com/";

const BinanceNetworkConfig = {
    chainNet: chainNet,
    chainNetId: chainNetId,
    chainName: chainName,
    chainRPC: chainRPC,
    chainSymbol: chainSymbol,
    chainExplorer: chainExplorer
}

const EthereumNetworkConfig = {
    chainNet: ethereumChainNet,
    chainNetId: ethereumChainNetId,
    chainName: ethereumChainName,
    chainRPC: ethereumChainRPC,
    chainSymbol: ethereumChainSymbol,
    chainExplorer: ethereumChainExplorer
}

const KlayNetworkConfig = {
    chainNet: klayChainNet,
    chainNetId: klayChainNetId,
    chainName: klayChainName,
    chainRPC: klayChainRPC,
    chainSymbol: klayChainSymbol,
    chainExplorer: klayChainExplorer
}

export const networkConfig = (id) => {
    if (id == chainNetId) {
        return BinanceNetworkConfig;
    }else if (id == ethereumChainNetId) {
        return EthereumNetworkConfig;
    }else if (id == klayChainNetId) {
        return KlayNetworkConfig;
    }
}

export const packIds = {
    MISAD: 2,
    BUSD: 1
}

export const maginekoPackIds = {
    USDT: 1,
    MISAD: 2
}

export const packPrices = {
    MISAD: 1500,
    BUSD: 500
}

export const maginekoPackPrices = {
    USDT: 32,
    MISAD: 250
}

export const buyNftConfig = {
    chain: chainNet,
    token_address: contractAddressByType['MISADNft'],
    contract_abi: misadNftTestContract,
    functionName: 'purchasePack',
    tokenName: 'MISADNft',
}

export const buyMaginekoConfig = {
    chain: ethereumChainNet,
    token_address: contractAddressByType['MAGINEKO'],
    contract_abi: misadNftTestContract,
    functionName: 'purchasePack',
    tokenName: 'Magineko',
}

export const getCardSetsConfig = {
    chain: chainNet,
    token_address: contractAddressByType['MISADNft'],
    contract_abi: misadNftTestContract,
    functionName: 'cardSets',
    tokenName: 'MISADNft',
}

export const approveMisad = {
    chain: chainNet,
    token_address: contractAddressByType['MISAD'],
    contract_abi: misadTestContract,
    functionName: 'approve',
    tokenName: 'MISAD',
}

export const approveBUSD = {
    chain: chainNet,
    token_address: contractAddressByType['BUSD'],
    contract_abi: misadTestContract,
    functionName: 'approve',
    tokenName: 'BUSD',
}