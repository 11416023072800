import { useWeb3Contract, useMoralis } from "react-moralis";
import { chainNetId, networkConfig, chainName, chainRPC, chainSymbol, chainExplorer, ethereumChainNetId, klayChainNetId } from 'configs/buyNftConfig';
import { contractAddressByType, currencySymbol } from "configs/contractAddress";

const binanceChainName = "BINANCE";
const ethereumChainName = "ETHEREUM";
const klayChainName = "KLAY";

export const MoralisHandleChain = async (Moralis, action, id, cancelAction) => {
    let chainId = await Moralis.getChainId();

    if (!id) {
        id = chainNetId
    }

    if (chainId != id) {
        Moralis.onChainChanged(async (chain) => {
            if (chain == id) {
                await action();
            }
        });

        try {
            await Moralis.switchNetwork(id); 
        }catch(error) {
            if (error.code == 4902) {
                const network = networkConfig(id);

                await Moralis.addNetwork(network.chainNetId, network.chainName, network.chainSymbol, network.chainSymbol, network.chainRPC, network.chainExplorer);
            }else if (cancelAction) {
                cancelAction();
            }
        }
    }else{
        await action();
    }
}

export const ImportToken = async () => {
    const tokenAddress = contractAddressByType['MISAD'];
    const tokenSymbol = currencySymbol;
    const tokenDecimals = 18;
    const tokenImage = 'https://bscscan.com/token/images/wingofmisadventure_32.png';

    if (typeof window.ethereum !== 'undefined') {
        const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: tokenAddress,
                    symbol: tokenSymbol,
                    decimals: tokenDecimals,
                    image: tokenImage,
                },
            },
        });
    
        return wasAdded;
    }
}

export const IsNativeToken = (token) => {
    return (token == "BNB" || token == "ETHEREUM")
}

export const GetTokenChainName = (token) => {
    switch(token) {
        case "BNB":
            return binanceChainName;
        case "MISAD":
            return binanceChainName;
        case "ETHEREUM":
            return ethereumChainName;
    }
}

export const GetChainNetIdByName = (chainName) => {
    switch(chainName) {
        case ethereumChainName:
            return ethereumChainNetId;
        case binanceChainName:
            return chainNetId;
        case klayChainName:
            return klayChainNetId;
    }
}

export const GetChainNativeToken = (chainName) => {
    switch(chainName) {
        case ethereumChainName:
            return "ETHEREUM";
        case binanceChainName:
            return "BNB";
    }
}