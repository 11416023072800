import React, { useEffect, useState, useContext } from 'react';
import { Box, Modal } from '@mui/material';
import Web3Button from 'components/Web3Button';
import { useMoralis } from 'react-moralis'
import { UserContext } from 'helpers/UserContext';
import { contractAddressByType, currencyName } from 'configs/contractAddress';
import { approveMisad, approveBUSD, buyNftConfig, chainNet, packIds } from 'configs/buyNftConfig'
import Backdrop from 'components/Backdrop';
import { toast } from 'react-toastify';
import LocalizatedMessage from 'localization';
import misadTestContract from 'contracts/misadTestContract'
import misadContract from 'contracts/misadContract';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'rgba(27, 27, 27, 1.0)',
    color: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};

export default function BuyNftModal({ currency, open, setOpen, price, chain, tokenContract, purchasePackJson, packId }) {
    const { userWallet } = useContext(UserContext)
    const { Moralis } = useMoralis()
    const [allowance, setAllowance] = useState('0')
    const [loading, setLoading] = useState(true)

    const approve = {
        chain: chain,
        token_address: contractAddressByType[currency],
        contract_abi: misadTestContract,
        functionName: 'approve',
        tokenName: currencyName[currency],
    }

    //Localization
    const purchaseNft = LocalizatedMessage('purchaseNft')
    const purchaseButton = LocalizatedMessage('purchaseButton')
    const buyNftPrice = LocalizatedMessage('buyNftPrice')

    const handleCheckAllowance = async () => {
        setLoading(true)

        if (userWallet) {
            try {
                var allowance = await getAllowance();
                
                setAllowance(allowance)
                return setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }

    const getAllowance = async () => {
        var provider = await Moralis.enableWeb3();
        var ethers = Moralis.web3Library;
        var signer = provider.getSigner();
        var contract = new ethers.Contract(contractAddressByType[currency], misadContract, signer);

        return await contract.allowance(userWallet, contractAddressByType[tokenContract]);
    }

    useEffect(() => {
        setTimeout(() => {
            handleCheckAllowance()
        }, 1500)
    }, []);

    return (
        <div>
            <Backdrop loading={loading} />
            <Modal
                open={open}
                onClose={setOpen}
            >
                <Box sx={modalStyle} className="defaultBox">
                    <div class="defaultMessage">{buyNftPrice.format(price, currencyName[currency])}</div>

                    <div class="dtlMenu">
                        <Web3Button
                            spenderAddress={contractAddressByType[tokenContract]}
                            userWallet={userWallet}
                            parentIsLoading={loading}
                            allowance={allowance}
                            setAllowance={setAllowance}
                            funOnSuccess={() => {
                                toast.success({ purchaseNft })
                                setOpen(false)
                            }}
                            runContractParams={{ packId: packId }}
                            runContractJson={purchasePackJson}
                            runApproveJson={approve}
                            btnLabel={purchaseButton}
                        />
                    </div>
                </Box>

            </Modal>
        </div>
    );
}















