import React from 'react';
import LocalizatedMessage from 'localization';

const Maintenance = () => {
    //Localization
    const maintenance = LocalizatedMessage('maintenance')
    
    return (
        <>
            <h4>{maintenance}</h4>
        </>
    );
}

export default Maintenance;