export const messagesInEnglish = {
    buyNft: "Buy NFT",
    myNfts: "My NFTs",
    buyNftMessage: "Buy a NFT Remnant",
    buyNftButton: "Buy NFT",
    nftPrice: "For {0} ${1}",
    myNftTitle: "My Remnant NFTs",
    purchaseNft: "Purchase Successful",
    purchaseButton: "PURCHASE",
    approveSuccessful: "Approve Successful",
    transactionFailed: "Transaction Failed, this can be due to insufficient funds",
    approve: "APPROVE",
    connectWallet: "CONNECT WALLET",
    buyNftPrice: "BUY NFT FOR {0} {1}",
    authorizationFailed: "Authorization Failed",
    walletRequired: "A connected wallet is required",
    failedLoadNfts: "Failed to load account NFTs",
    connectWebservice: "Connect to Wing WebService",
    loading: "Loading...",
    imageLoadingFailed: "Failed to load NFT image",
    accountNftLoadingFailed: "Failed to load account NFTs",
    nftBuySuccess: "Congratulations, here is your NFT!",
    errorOpenNft: "Error while opening NFT",
    nextNftFailed: "You dont have more NFTs",
    prevNftFailed: "You dont have more previous NFTs",
    showingNft: "Showing NFT {0} of {1}",
    previous: "Previous",
    next: "Next",
    skill: "Skill",
    affinity: "Affinity",
    willpower: "Willpower",
    heredity: "Heredity",
    personality: "Personality",
    heart: "Heart",
    noNfts: "You dont have any Remnant NFTs yet",
    noLandsNfts: "You dont have any Land NFTs yet",
    noSoulsNfts: "You dont have any Soul NFTs yet",
    remnants: "Remnants",
    lands: "Lands",
    souls: "Souls",
    common: "Common",
    uncommon: "Uncommon",
    rare: "Rare",
    divine: "Divine",
    remnant0type0: "Remnant of Oto (light)",
    remnant0type1: "Remnant of Lyaloto (wind)",
    remnant0type2: "Remnant of Arunof (water)",
    remnant1type0: "Remnant of Kyurin (beast)",
    remnant1type1: "Remnant of Arkin (dark)",
    remnant1type2: "Remnant of Ravlas (light)",
    remnant2type0: "Remnant of Elae (wind)",
    remnant2type1: "Remnant of Praun (fire)",
    remnant2type2: "Remnant of Opus (dark)",
    remnant3type0: "Remnant of Criatia (light)",
    remnant3type1: "Remnant of Aigui (dark)",
    remnant3type2: "Remnant of Iamai (fire)",
    myLands: "My Lands",
    mySouls: "My Souls",
    bless: "Bless",
    weather: "Weather",
    god: "God",
    stake: "Send to Stake",
    removeStake: "Remove from Stake",
    stakeSent: "NFT Sent to Stake",
    mystake: "Stake",
    noStakeNfts: "You dont have any NFTs in Stake",
    rentability: "Receiving {0} MISAD/day",
    stakeRemoved: "NFT sent for removal",
    webservicefailed: "Could not authenticate to WebService, try reload the page",
    webservicedown: "Could not connect WebService, try again later",
    mystakenfts: "NFTs",
    mystakemisad: "MISAD",
    stakeFailed: "Failed to retrieve MISAD Stake details",
    stakeValue: "Total in stake: {0} MISAD",
    stakeLastPercentage: "Last rentability {0}",
    reload: "Reload",
    depositStake: "Deposit",
    withdrawStake: "Withdraw",
    invalidValue: "Invalid value",
    stakeSuccess: "Value send to stake, refresh the page in a minute",
    stakeWithdrawSuccess: "Withdraw request sent",
    transactionInProgress: "Another transaction is in progress",
    valuePlaceholder: "Value here (ex: 0.01)",
    stakeLogMovement: "Movement",
    stakeLogOldValue: "Old value",
    stakeLogNewValue: "New value",
    stakeLogDescription: "Description",
    stakeLogFailed: "Failed to retrieve stake log",
    stakeLogPrev: "Previous page",
    stakeLogNext: "Next page",
    stakeLogPage: "Page {0} of {1}",
    nftDetails: "Details",
    nftOpen: "Open",
    remnant: "Remnant",
    land: "Land",
    soul: "Soul",
    ok: "Okay",
    cancel: "Cancel",
    withdrawMessage: "In order to request a withdraw, an amount of {0} BNB is required to deal with the blockchain gas cost. Do you want to continue?",
    nftTypesButton: "?",
    actualStake: "Total rewards: {0} MISAD",
    baseAPR: "Current APR: {0}%",
    pageable: "Page {0} of {1}",
    purchaseFailed: "Transaction failed, this can be due to insufficient funds",
    deposit: "Deposit",
    withdraw: "Withdraw",
    awaiting: "Awaiting",
    success: "Success",
    failure: "Failure",
    error: "Error",
    fraud: "Fraud",
    confirmed: "Already confirmed",
    value: "Value",
    status: "Status",
    myremnants: "My Remnants",
    buyWith: "Buy with:",
    lockStake: "In order to stake MISAD you need to choose a stake lock period below, during this time you can't withdraw your MISADs:",
    changeLockStake: "Choose the new lock period starting on {0}:",
    lock1Month: "1 Months",
    lock3Months: "3 Months",
    lock6Months: "6 Months",
    lock12Months: "12 Months",
    maxApr: "Max APR: {0}%",
    changeLockButton: "Increase lock date",
    lockDate: "Locked until {0}",
    lockUpdated: "Lock date updated!",
    restake: "Restake",
    restakeConfirm: " A tax of {0}% MISAD (MAX of 0.5 MISAD) from your rewards is needed due to be transferred into stake. Are you sure you want to restake your MISADs?",
    restakeSuccess: "Restake done with success!",
    claim: "Claim",
    claimTransaction: "Withdraw Rewards",
    claimValue: "All rewards",
    date: "Date",
    WITHDRAW_REWARD: "Withdraw rewards",
    REWARD_RESTAKE: "Restake rewards",
    TAX: "APR Tax",
    WITHDRAW: "Withdraw",
    DEPOSIT: "Deposit",
    cardSetsFailed: "Failed to retrieve NFTs selling info",
    notWhitelisted: "Your wallet is not in the whitelist for this NFT selling",
    openNftConfirm: "You really want to open this NFT?",
    valuePrompt: "Type the desired value:",
    english: "English",
    portuguese: "Portuguese",
    japanese: "Japanese",
    claimText: "To which destination you want to claim your rewards?",
    claimPrimaryButton: "My wallet",
    claimSecondaryButton: "My stake",
    myStake: "My Stake",
    stakedValue: "Staked Value",
    stakedNFTs: "Staked NFTs",
    dailyRewards: "Daily Rewards",
    nftStakeStats: "NFT Stake Stats",
    totalSupply: "Total Supply",
    nftStats: "NFT Stats",
    total: "Total",
    apr: "APR",
    maxApr: "MAX APR",
    upgrade: "Upgrade",
    availableRewards: "Available Rewards",
    stake: "Stake",
    unstake: "Unstake",
    stakeLockTime: "Unstake Lock time: {0}",
    flexibleUnstake: "Flexible Unstake",
    globalApr: "MISAD Global APR",
    stats: "MISAD Stats",
    totalStaked: "Total Staked",
    misadPrice: "MISAD PRICE",
    poweredBy: "Powered by",
    misadChain: "Misad Chain Network",
    transactionLog: "Transaction Log",
    remnantAnnouncement: "Participate in limited Remnants offers! Misadventure's most important NFT and also your faithful companion!",
    remnantReadmore: "Read more about Remnants",
    nftStakeTitle: "NFT Stake",
    purchaseSuccessful: "Purchase successful, NFT incoming!",
    myNFTStake: "My NFT Stake",
    type: "Type",
    totalRewards: "Total Rewards",
    misadStake: "MISAD Stake",
    unlisted: "Unlisted",
    close: "Close",
    confirm: "Confirm",
    lockAPRCheckBoxConfirmation: "I understand that i will not be able to unstake the $MISAD for the selected time",
    stakeValueTip: "You will choose the APR lock date after confirmation",
    stakeValueTip2: "You will not be able to unstake $MISAD until the lock date",
    lockMonths: "{0} Months",
    maxAprPercentage: "{0}% MAX APR",
    presale: "Presale",
    governance: "Governance",
    maintenance: "The Dapp is currently on a maintenance. We expect to come back in 03/05.",
    download: "Download",
    maintenanceFunction: "This function is under maintenance at the moment, please try again later.",
    copySharedLink: "Copy shared link",
    linkCopied: "Copied to clipboard",
    misadAdded: "MISAD was imported into your wallet!",
    misadError: "Failed to import MISAD, check your wallet and Metamask plugin",
    importMisad: "Import $MISAD",
    maginekos: "Maginekos",
    buyMaginekoNftMessage: "Buy a NFT Magineko",
    myMaginekos: "My Maginekos",
    noMaginekoNfts: "You dont have any Magineko NFT",
    equipments: "Equipments",
    petMagineko: "Pet this Magineko!",
    selectPaymentMethodMessage: "Select payment method:",
    lackOfPets: "This Magineko reached the daily pet limit",
    petComplete: "Meow! You pet this Magineko! Soon your drop will be delivered at items tab!",
    totalInWallet: "Total: {0}",
    withdrawToWallet: "Withdraw to wallet",
    failedLoadingBalance: "Failed to load wallet balance",
    withdrawWalletSuccess: "Wallet {0} withdraw requested",
    withdrawCostMessage: "In order to request a withdraw, an amount of {0} {1} is required to deal with the blockchain gas cost. Do you want to continue?",
    maginekoReadmore: "Read more about Maginekos",
    maginekoAnnouncement: "Participate in limited Magineko offers, the cutest companion in Misadventure! Guarantee free access to the Closed Beta!",
    maginekoPartBody: "Body",
    maginekoPartEye: "Eye",
    maginekoPartAccessory: "Accessory",
    maginekoPartClothing: "Clothing",
    maginekoPartWeapon: "Weapon",
    maginekoPartBackground: "Background",
    maginekoScore: "Rarity score: {0}",
    myAccount: "My Account",
    dashboard: "Dashboard",
    myEquipments: "My Equipments",
    noEquipmentNfts: "You dont have any item NFT",
    dailyPettings: "Today daily pettings: ",
    randomMaginekoLabel: "Want to get new rare/divine random items? Try to pet a Magineko!",
    randomMaginekoButton: "Find a random Magineko!",
    randomMaginekoAnother: "Find another Magineko",
    misads: "$4Tabern",
    buyMisads: "$4Tabern Special Offers",
    noActiveOffers: "No active offers",
    forPrice: "For ",
    buyCompleted: "You bought this package! Your delivery will be done in a few minutes",
    tokenStoreSendValue: "Convert {0}:",
    tokenStoreReceiveValue: "You receive {0}:",
    randomMaginekoAnother: "Find another Magineko",
    addMarketplaceOfferPrice: "Price (in BNB):",
    addMarketplaceOfferAgreement: "I comprehend that 6.5% of the order value will be taken as tax and my NFT will be send to Wing of Misadventure wallet until it got sold or i pay a withdraw tax to cancel my order.",
    addMarketplaceOffer: "Register offer",
    sell: "Sell",
    addMarketplaceOfferSuccess: "Offer request sent! Will be registered soon.",
    marketplace: "Marketplace",
    marketplaceFilter: "Filter results for",
    rarity: "Rarity",
    marketplaceSearch: "Search",
    noResults: "No results.",
    buyMarketplaceOffer: "Buy",
    marketplacePurchaseCompleted: "Purchase request sent! Your NFT will be delivered soon.",
    showingRecords: "Showing {0} of {1} records",
    orderBy: "Order by...",
    newest: "Newest",
    oldest: "Oldest",
    cheapPrice: "Cheapest price",
    highestPrice: "Highest price",
    weapon: "Weapon",
    shield: "Shield",
    gender: "Gender",
    male: "Male",
    female: "Female",
    accessory1Male: "Mask",
    accessory2Male: "Mustache",
    accessory3Male: "Smoking Pipe",
    accessory4Male: "Eyepatch",
    outfit1Male: "Red Coat",
    outfit2Male: "Armor",
    outfit3Male: "White Coat",
    outfit4Male: "Black Armor",
    outfit5Male: "Light Armor",
    eyes1Male: "Blue",
    eyes2Male: "Red",
    eyes3Male: "Yellow",
    eyes4Male: "Orange",
    eyes5Male: "Green",
    hair1Male: "Brown (Short)",
    hair2Male: "Dark Brown (Short)",
    hair3Male: "White (Short)",
    hair4Male: "Brown (Very Short)",
    hair5Male: "Light Blonde (Short)",
    hair6Male: "Blonde (Short)",
    accessory1Female: "Hat",
    accessory2Female: "Fur Ears",
    accessory3Female: "Glasses",
    accessory4Female: "Flower",
    accessory5Female: "Helmet",
    outfit1Female: "Dark Coat",
    outfit2Female: "Blue Armor",
    outfit3Female: "Blue Dress",
    outfit4Female: "White Coat",
    outfit5Female: "Dark Armor",
    eyes1Female: "Brown",
    eyes2Female: "Blue",
    eyes3Female: "Red",
    eyes4Female: "Orange",
    eyes5Female: "Green",
    hair1Female: "Blue (Long)",
    hair2Female: "Brown (Short)",
    hair3Female: "White (Long)",
    hair4Female: "Light Brown (Long)",
    hair5Female: "Blonde (Long)",
    none: "None",
    accessory: "Accessory",
    outfit: "Outfit",
    eyes: "Eyes",
    hair: "Hair",
    maginekoBackground1: "Temple",
    maginekoBackground2: "Castle",
    maginekoBackground3: "Cat Sphere",
    maginekoBackground4: "Ship",
    maginekoMaleHat1: "Pirate Hat",
    maginekoMaleHat2: "Blue Feather Hat",
    maginekoMaleHat3: "Yellow Feather Hat",
    maginekoMaleHat4: "Red Topper",
    maginekoMaleHat5: "Yellow Topper",
    maginekoMaleHat6: "Blue Topper",
    maginekoMaleHat7: "Blue Samurai Helmet",
    maginekoMaleHat8: "Red Samurai Helmet",
    maginekoMaleHat9: "Beret",
    maginekoMaleHat10: "Straw Hat",
    maginekoMaleFur1: "Brown",
    maginekoMaleFur2: "Siamese",
    maginekoMaleFur3: "Tigress",
    maginekoMaleFur4: "White",
    maginekoMaleFur5: "Brown & White",
    maginekoEyes1: "Blue",
    maginekoEyes2: "Orange",
    maginekoEyes3: "Yellow",
    maginekoEyes4: "Purple",
    maginekoEyes5: "Green",
    maginekoMaleOutfit1: "Archer Cloths",
    maginekoMaleOutfit2: "Red Coat",
    maginekoMaleOutfit3: "Yellow Coat",
    maginekoMaleOutfit4: "Blue Coat",
    maginekoMaleOutfit5: "Blue Armor",
    maginekoMaleOutfit6: "Yellow Armor",
    maginekoMaleOutfit7: "Red Samurai Armor",
    maginekoMaleOutfit8: "Blue Samurai Armor",
    maginekoMaleOutfit9: "Pirate Cloths",
    maginekoMaleOutfit10: "4Tabern Kimono",
    maginekoMaleOutfit11: "Red Kimono",
    maginekoMaleOutfit12: "Yellow Kimono",
    maginekoMaleOutfit13: "Purple Kimono",
    maginekoMaleWeapon1: "Sword",
    maginekoMaleWeapon2: "Pistol",
    maginekoMaleWeapon3: "Bow",
    maginekoMaleWeapon4: "Staff",
    maginekoMaleWeapon5: "Katana",
    maginekoMaleWeapon6: "Axe",
    maginekoMaleWeapon7: "Dagger",
    maginekoFemaleHat1: "Red Hat",
    maginekoFemaleHat2: "Green Hat",
    maginekoFemaleHat3: "Monocle",
    maginekoFemaleHat4: "Pirate Hat",
    maginekoFemaleHat5: "Tiara",
    maginekoFemaleHat6: "Samurai Helmet",
    maginekoFemaleHat7: "Red Mask",
    maginekoFemaleHat8: "Blue Mask",
    maginekoFemaleFur1: "Brown",
    maginekoFemaleFur2: "Tricolor",
    maginekoFemaleFur3: "Tigress",
    maginekoFemaleFur4: "White",
    maginekoFemaleFur5: "Black",
    maginekoFemaleEyes1: "Red",
    maginekoFemaleEyes2: "Blue",
    maginekoFemaleEyes3: "Yellow",
    maginekoFemaleEyes4: "Pink",
    maginekoFemaleEyes5: "Green",
    maginekoFemaleOutfit1: "Pirate Cloths",
    maginekoFemaleOutfit2: "Red Armor",
    maginekoFemaleOutfit3: "Blue Armor",
    maginekoFemaleOutfit4: "Red Dress",
    maginekoFemaleOutfit5: "Purple Dress",
    maginekoFemaleOutfit6: "White Dress",
    maginekoFemaleOutfit7: "Blue Samurai Armor",
    maginekoFemaleOutfit8: "Red Samurai Armor",
    maginekoFemaleOutfit9: "Yellow Samurai Armor",
    maginekoFemaleOutfit10: "4Tabern Kimono",
    maginekoFemaleOutfit11: "Orange Dress",
    maginekoFemaleOutfit12: "Brown Dress",
    maginekoFemaleOutfit13: "Blue Dress",
    maginekoFemaleWeapon1: "Sword",
    maginekoFemaleWeapon2: "Orange Staff",
    maginekoFemaleWeapon3: "Purple Staff",
    maginekoFemaleWeapon4: "Nature Staff",
    maginekoFemaleWeapon5: "Bow",
    maginekoFemaleWeapon6: "Pistol",
    maginekoFemaleWeapon7: "Katana",
    maginekoFemaleWeapon8: "Dagger",
    maginekoFemaleWeapon9: "Axe",
    landType1: "Castle",
    landType2: "City",
    landType3: "Forest",
    landType4: "Village",
    landBless1: "Agility",
    landBless2: "Intelligence",
    landBless3: "Strength",
    landBless4: "Wisdom",
    landWeather1: "Misty",
    landWeather2: "Rain",
    landWeather3: "Snow",
    landWeather4: "Sunny",
    landGod1: "Criatia",
    landGod2: "Lyaloto",
    landGod3: "Oto",
    landGod4: "Ravlas",
    landGod5: "5",
    landGod6: "6",
    landGod7: "7",
    landGod8: "8",
    landGod9: "9",
    landGod10: "10",
    landGod11: "11",
    landGod12: "12",
    landGod13: "13",
    marketplaceOrders: "Marketplace Offers",
    marketplaceLogTokenType: "Token Type",
    marketplaceLogTokenId: "Token ID",
    marketplaceLogPrice: "Price",
    marketplaceLogStatus: "Status",
    marketplaceLogCreatedDate: "Created",
    marketplaceLogActions: "Actions",
    magineko: "Magineko",
    equipment: "Equipment",
    active: "Active",
    completed: "Completed",
    cancelled: "Cancelled",
    requestNftDataFailed: "Failed to retrieve NFT data",
    confirmOfferCancel: "In order to cancel a marketplace offer, an amount of {0} BNB is required to deal with the blockchain gas cost for transferring the NFT back to your wallet. Do you want to continue?",
    cancelOfferRequestSent: "Offer cancel request sent! Your NFT will be send back to your wallet in a few minutes.",
    nftView: "View",
    criptoAnnouncement: "Participate in limited $4Tabern offers! Our 4Tabern Studio Governance Token!",
    tokenStoreMaxValue: "Your value exceeds the max tokens available (Max: {0})"
}
