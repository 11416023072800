export const messagesInJapanese = {
    buyNft: "NFTを購入する",
    myNfts: "私のNFT",
    buyNftMessage: "レムナントNFTを購入する",
    buyNftButton: "NFTを購入する",
    nftPrice: "NFTを{0}${1}で購入",
    myNftTitle: "私のレムナントNFT",
    purchaseNft: "購入成功",
    purchaseButton: "購入",
    approveSuccessful: "承認成功",
    transactionFailed: "資金不足のため、トランザクションに失敗しました",
    approve: "承認",
    connectWallet: "ウォレットに接続",
    buyNftPrice: "NFTを購入する{0} {1}",
    authorizationFailed: "認証に失敗しました",
    walletRequiレッド: "ウォレットを接続してください",
    failedLoadNfts: "所有しているNFTの読み込みに失敗しました",
    connectWebservice: "Wing WebServiceへ接続します",
    loading: "ロード中...",
    imageLoadingFailed: "NFT画像のロードに失敗しました",
    accountNftLoadingFailed: "所有しているNFTの読み込みに失敗しました",
    nftBuySuccess: "おめでとうございます、NFTの購入に成功しました!",
    errorOpenNft: "NFTを開く際にエラーが発生しました",
    nextNftFailed: "これ以上NFTを所有していません",
    prevNftFailed: "これより前のNFTはありません",
    showingNft: "NFTの{0} ページ中の {1}ページ",
    previous: "前",
    next: "次",
    skill: "スキル",
    affinity: "相性",
    willpower: "意志力",
    heレッドity: "遺伝",
    personality: "性格",
    heart: "勇敢さ",
    noNfts: "あなたはまだレムナントのNFTを所有していません",
    noLandsNfts: "あなたはまだ土地のNFTを所有していません",
    noSoulsNfts: "あなたはまだソウルNFTを所有していません",
    remnants: "レムナント",
    lands: "土地",
    souls: "ソウルズ",
    common: "コモン",
    uncommon: "アンコモン",
    rare: "レア",
    divine: "ディバイン",
    remnant0type0: "レムナント Oto (光)",
    remnant0type1: "レムナント Lyaloto (風)",
    remnant0type2: "レムナント Arunof (水)",
    remnant1type0: "レムナント Kyurin (獣)",
    remnant1type1: "レムナント Arkin (闇)",
    remnant1type2: "レムナント Ravlas (光)",
    remnant2type0: "レムナント Elae (風)",
    remnant2type1: "レムナント Praun (火)",
    remnant2type2: "レムナント Opus (闇)",
    remnant3type0: "レムナント Criatia (光)",
    remnant3type1: "レムナント Aigui (闇)",
    remnant3type2: "レムナント Iamai (火)",
    myLands: "私の土地",
    mySouls: "私のソウルズ",
    bless: "祝福",
    weather: "天気",
    god: "神",
    stake: "ステークに送る",
    removeStake: "ステークから削除する",
    stakeSent: "NFTをステークに送る",
    mystake: "ステーク",
    noStakeNfts: "あなたはステークしているNFTを所有していません",
    rentability: "獲得 {0} MISAD/日",
    stakeRemoved: "NFTをステークから解除します",
    webservicefailed: "ウェブサービスに接続できません。ページを再読み込みしてください",
    webservicedown: "ウェブサービスに接続できません。後で再試行してください",
    mystakenfts: "NFT",
    mystakemisad: "MISAD",
    stakeFailed: "MISADステークの取り出しに失敗しました",
    stakeValue: "合計ステーク: {0} MISAD",
    stakeLastPercentage: "最終報酬 {0}",
    reload: "リロード",
    depositStake: "預金",
    withdrawStake: "引き出し",
    invalidValue: "無効な値",
    stakeSuccess: "ステークが成功しました、ページを更新してください",
    stakeWithdrawSuccess: "引き出し要求が送信されました",
    transactionInProgress: "別のトランザクションが進行中です",
    valuePlaceholder: "金額はここに表示されます (ex: 0.01)",
    stakeLogMovement: "移動",
    stakeLogOldValue: "古い価値",
    stakeLogNewValue: "新しい価値",
    stakeLogDescription: "説明",
    stakeLogFailed: "ステークログの取得に失敗しました",
    stakeLogPrev: "前のページ",
    stakeLogNext: "次のページ",
    stakeLogPage: "ページ{0}の {1}",
    nftDetails: "詳細",
    nftOpen: "開く",
    remnant: "レムナント",
    land: "土地",
    soul: "ソウルズ",
    ok: "承認",
    cancel: "キャンセル",
    withdrawMessage: "引き出しを要求する場合、ブロックチェーンガス代として{0} BNBが必要です。実行しますか?",
    actualStake: "合計報酬: {0} MISAD",
    baseAPR: "現在のAPR: {0}%",
    pageable: "ページ{0} の {1}",
    purchaseFailed: "取引に失敗しました、これは資金不足が原因である可能性があります",
    deposit: "預金",
    withdraw: "引き出し",
    awaiting: "待機中",
    success: "成功",
    failure: "失敗",
    error: "エラー",
    fraud: "不正",
    confirmed: "確認済み",
    value: "価値",
    status: "ステータス",
    myremnants: "私のレムナント",
    buyWith: "購入:",
    lockStake: "MISADをステークするには、以下のロック期間を決める必要があります。ロック期間中、MISADは引き出せません",
    changeLockStake: "ロック期間を選択してください{0}:",
    lock1Month: "1ヶ月",
    lock3Months: "3ヶ月",
    lock6Months: "6ヶ月",
    lock12Months: "12ヶ月",
    maxApr: "最大APR: {0}%",
    changeLockButton: "ロック期間を増やす",
    lockDate: "{0}までロックされる",
    lockUpdated: "ロック日が更新されました!",
    restake: "ステークし直す",
    restakeConfirm: " あなたの報酬から{0}%のMISADの税金がステークに振り込まれるため必要です。本当にMISADを再ステークしますか？",
    restakeSuccess: "再ステークに成功しました！",
    claim: "引き出し",
    claimTransaction: "報酬を引き出す",
    claimValue: "すべての報酬",
    date: "日付",
    WITHDRAW_REWARD: "報酬を引き出す",
    REWARD_RESTAKE: "再ステーク報酬",
    TAX: "APR税",
    WITHDRAW: "引き出す",
    DEPOSIT: "預ける",
    cardSetsFailed: "NFTの販売データの取得に失敗しました",
    notホワイトlisted: "あなたのウォレットはこのNFT購入のホワイトリストに登録されていません",
    openNftConfirm: "本当にこのNFTを開けていいですか?",
    valuePrompt: "希望する金額を入力してください:",
    english: "英語",
    portuguese: "ポルトガル語",
    japanese: "日本語",
    claimText: "報酬の引き出し先はここで合ってますか?",
    claimPrimaryButton: "私のウォレット",
    claimSecondaryButton: "私のステーク",
    myStake: "私のステーク",
    stakedValue: "ステーク金額",
    stakedNFTs: "NFTのステーク",
    dailyRewards: "デイリー報酬",
    nftStakeStats: "NFTのステーク状況",
    totalSupply: "総供給量",
    nftStats: "NFTの統計",
    total: "合計",
    apr: "APR",
    maxApr: "最大APR",
    upgrade: "アップグレード",
    availableRewards: "引き出し可能なリワード",
    misadStake: "ステーク",
    unstake: "ステーク解除",
    stakeLockTime: "ステーク解除日: {0}",
    flexibleUnstake: "ステークを解除する",
    globalApr: "MISADのグローバルAPR",
    stats: "MISADの統計",
    totalStaked: "ステークした合計",
    misadPrice: "MISADの価値",
    poweレッドBy: "Poweレッド by",
    misadChain: "Misad Chain Network",
    transactionLog: "トランザクションログ",
    remnantAnnouncement: "レムナントの限定特典を手に入れよう!レムナントNFTはMisadventure'sの最も重要なNFTであり、あなたの忠実な仲間です!",
    remnantReadmore: "レムナントについて詳しく読む",
    nftStakeTitle: "NFTステーク",
    purchaseSuccessful: "NFTの購入に成功しました!",
    myNFTStake: "私のNFTのステーク",
    type: "タイプ",
    totalRewards: "合計報酬",
    misadStake: "MISADステーク",
    unlisted: "未上場",
    close: "閉じる",
    confirm: "確認",
    lockAPRCheckBoxConfirmation: "選択した期間、MISADのステークが解除できないことを理解しました。",
    stakeValueTip: "確認後、APRロック日を選択することになります",
    stakeValueTip2: "ロック期日まで$MISADをアンステークすることはできません",
    lockMonths: "{0} 月",
    maxAprPercentage: "{0}% MAX APR",
    presale: "プレセール",
    governance: "ガバナンス",
    maintenance: "このページは現在メンテナンス中です。また後で来てください。",
    download: "ダウンロード",
    maintenanceFunction: "この機能は現在メンテナンス中ですので、後ほど再度お試しください。",
    copyShaレッドLink: "共有リンクをコピーする",
    linkCopied: "クリップボードにコピー",
    misadAdded: "MISADがウォレットにインポートさました!",
    misadError: "MISADのインポートに失敗しました。ウォレットとMetaマスクプラグインを確認してください。",
    importMisad: "インポート $MISAD",
    maginekos: "マギネコ",
    buyMaginekoNftMessage: "マギネコNFTを購入する",
    myMaginekos: "私のマギネコ",
    noMaginekoNfts: "あなたはマギネコNFTを持っていません",
    equipments: "装備",
    petMagineko: "このマギネコを撫でてください!",
    selectPaymentMethodMessage: "支払い方法の選択:",
    lackOfPets: "このマギネコは1日のナデナデ制限に達した",
    petComplete: "ニャーニャー このマギネコをなでたニャ! もうすぐアイテムタブにドロップが届くニャ!",
    totalInWallet: "合計l: {0}",
    withdrawToWallet: "ウォレットへの出金",
    failedLoadingBalance: "ウォレットバランスの読み込みに失敗しました",
    withdrawWalletSuccess: "ウォレット {0} の引き出しを要求",
    withdrawCostMessage: "出金依頼には、ブロックチェーンガス代として{0} {1}の金額が必要です。続けますか？",
    maginekoReadmore: "マギネコについてもっと読む",
    maginekoAnnouncement: "マギネコ限定特典に参加しよう Misadventureの中で一番かわいい仲間",
    maginekoPartBody: "体",
    maginekoPartEye: "目",
    maginekoPartAccessory: "アクセサリー",
    maginekoPartClothing: "洋服",
    maginekoPartWeapon: "武器",
    maginekoPartBackground: "背景",
    maginekoScore: "レアリティスコア: {0}",
    myAccount: "私のアカウント",
    dashboard: "ダッシュボード",
    myEquipments: "私の装備",
    noEquipmentNfts: "アイテムがない",
    dailyPettings: "今日の日替わりナデナデ回数",
    randomMaginekoLabel: "新たなレアアイテムをゲットしたいですか？マギネコを撫でてみてください！",
    randomMaginekoButton: "ランダムなマギネコを見つけよう！",
    randomMaginekoAnother: "別のマギネコを見つける",
    randomMaginekoAnother: "別のマギネコを見つける",
    addMarketplaceOfferPrice: "価格 (BNB):",
    addMarketplaceOfferAgreement: "注文金額の6.5%が税金として取られ、私のNFTが売却されるまでWing of Misadventureウォレットに送られるか、私が注文をキャンセルするために引き出し税を支払うことになると理解しています",
    addMarketplaceOffer: "オファーを登録する",
    sell: "売却",
    addMarketplaceOfferSuccess: "オファーリクエストを送信しました。まもなく登録されます",
    marketplace: "マーケットプレイス",
    marketplaceFilter: "の検索結果",
    rarity: "レアリティ",
    marketplaceSearch: "検索",
    noResults: "見つかりませんでした",
    buyMarketplaceOffer: "購入",
    marketplacePurchaseCompleted: "購入リクエストを送信しました！NFTはまもなくお届けします",
    showingRecords: " {1}件の記録のうち {0} を表示します",
    orderBy: "注文中...",
    newest: "最新順",
    oldest: "古い順",
    cheapPrice: "最安値",
    highestPrice: "最高値",
    weapon: "武器",
    shield: "盾",
    gender: "性別",
    male: "男性",
    female: "女性",
    accessory1Male: "マスク",
    accessory2Male: "口ひげ",
    accessory3Male: "喫煙パイプ",
    accessory4Male: "アイ・パッチ",
    outfit1Male: "レッドコート",
    outfit2Male: "アーマー",
    outfit3Male: "ホワイトコート",
    outfit4Male: "ブラックアーマー",
    outfit5Male: "ライトアーマー",
    eyes1Male: "ブルー",
    eyes2Male: "レッド",
    eyes3Male: "イエロー",
    eyes4Male: "オレンジ",
    eyes5Male: "グリーン",
    hair1Male: "ブラウン (ショート)",
    hair2Male: "ダークブラウン (ショート)",
    hair3Male: "ホワイト (ショート)",
    hair4Male: "ブラウン (ベリーショート)",
    hair5Male: "ライトブロンド (ショート)",
    hair6Male: "ブロンド (ショート)",
    accessory1Female: "帽子",
    accessory2Female: "毛皮の耳",
    accessory3Female: "メガネ",
    accessory4Female: "花",
    accessory5Female: "ヘルメット",
    outfit1Female: "ダークコート",
    outfit2Female: "ブルーアーマー",
    outfit3Female: "青いドレス",
    outfit4Female: "ホワイトコート",
    outfit5Female: "ダークアーマー",
    eyes1Female: "ブラウン",
    eyes2Female: "ブルー",
    eyes3Female: "レッド",
    eyes4Female: "オレンジ",
    eyes5Female: "グリーン",
    hair1Female: "ブルー (ロング)",
    hair2Female: "ブラウン (ショート)",
    hair3Female: "ホワイト (ロング)",
    hair4Female: "ライトブラウン (ロング)",
    hair5Female: "ブロンド (ロング)",
    none: "なし",
    accessory: "アクセサリー",
    outfit: "衣装",
    eyes: "目",
    hair: "ヘアー",
    maginekoBackground1: "神殿",
    maginekoBackground2: "城",
    maginekoBackground3: "キャットスフィア",
    maginekoBackground4: "船",
    maginekoMalehat1: "海賊帽子",
    maginekoMalehat2: "青い羽帽子",
    maginekoMalehat3: "黄色い羽帽子",
    maginekoMalehat4: "赤いシルクハット",
    maginekoMalehat5: "黄色いシルクハット",
    maginekoMalehat6: "青いシルクハット",
    maginekoMalehat7: "ブルーサムライヘルメット",
    maginekoMalehat8: "レッドサムライヘルメット",
    maginekoMalehat9: "ベレー帽",
    maginekoMalehat10: "麦わら帽子",
    maginekoMaleFur1: "ブラウン",
    maginekoMaleFur2: "シャム",
    maginekoMaleFur3: "タイグレス",
    maginekoMaleFur4: "ホワイト",
    maginekoMaleFur5: "ブラウン&ホワイト",
    maginekoEyes1: "ブルー",
    maginekoEyes2: "オレンジ",
    maginekoEyes3: "イエロー",
    maginekoEyes4: "パープル",
    maginekoEyes5: "グリーン",
    maginekoMaleOutfit1: "アーチャークロス",
    maginekoMaleOutfit2: "レッドコート",
    maginekoMaleOutfit3: "イエローコート",
    maginekoMaleOutfit4: "ブルーコート",
    maginekoMaleOutfit5: "ブルーアーマー",
    maginekoMaleOutfit6: "イエローアーマー",
    maginekoMaleOutfit7: "レッドサムライアーマー",
    maginekoMaleOutfit8: "ブルーサムライアーマー",
    maginekoMaleOutfit9: "海賊服",
    maginekoMaleOutfit10: "4Tabernの着物",
    maginekoMaleOutfit11: "赤色の着物",
    maginekoMaleOutfit12: "黄色の着物",
    maginekoMaleOutfit13: "紫色の着物",
    maginekoMaleWeapon1: "剣",
    maginekoMaleWeapon2: "銃",
    maginekoMaleWeapon3: "弓",
    maginekoMaleWeapon4: "杖",
    maginekoMaleWeapon5: "刀",
    maginekoMaleWeapon6: "斧",
    maginekoMaleWeapon7: "短剣",
    maginekoFemalehat1: "レッドハット",
    maginekoFemalehat2: "グリーンハット",
    maginekoFemalehat3: "片メガネ",
    maginekoFemalehat4: "パイレーツハット",
    maginekoFemalehat5: "ティアラ",
    maginekoFemalehat6: "サムライ ヘルメット",
    maginekoFemalehat7: "レッドマスク",
    maginekoFemalehat8: "ブルーマスク",
    maginekoFemaleFur1: "ブラウン",
    maginekoFemaleFur2: "トリコロール",
    maginekoFemaleFur3: "タイグレス",
    maginekoFemaleFur4: "ホワイト",
    maginekoFemaleFur5: "ブラック",
    maginekoFemaleEyes1: "レッド",
    maginekoFemaleEyes2: "ブルー",
    maginekoFemaleEyes3: "イエロー",
    maginekoFemaleEyes4: "ピンク",
    maginekoFemaleEyes5: "グリーン",
    maginekoFemaleOutfit1: "海賊服",
    maginekoFemaleOutfit2: "レッドアーマー",
    maginekoFemaleOutfit3: "ブルーアーマー",
    maginekoFemaleOutfit4: "赤いドレス",
    maginekoFemaleOutfit5: "紫色のドレス",
    maginekoFemaleOutfit6: "白いドレス",
    maginekoFemaleOutfit7: "ブルーサムライアーマー",
    maginekoFemaleOutfit8: "レッドサムライアーマー",
    maginekoFemaleOutfit9: "イエローサムライアーマー",
    maginekoFemaleOutfit10: "4Tabernの着物",
    maginekoFemaleOutfit11: "オレンジのドレス",
    maginekoFemaleOutfit12: "茶色いドレス",
    maginekoFemaleOutfit13: "青いドレス",
    maginekoFemaleWeapon1: "剣",
    maginekoFemaleWeapon2: "オレンジスタッフ",
    maginekoFemaleWeapon3: "パープルスタッフ",
    maginekoFemaleWeapon4: "ナチュラルスタッフ",
    maginekoFemaleWeapon5: "弓",
    maginekoFemaleWeapon6: "銃",
    maginekoFemaleWeapon7: "刀",
    maginekoFemaleWeapon8: "短剣",
    maginekoFemaleWeapon9: "斧",
    landType1: "城",
    landType2: "街",
    landType3: "森",
    landType4: "村",
    landBless1: "機敏性",
    landBless2: "知性",
    landBless3: "力",
    landBless4: "知恵",
    landWeather1: "霧",
    landWeather2: "雨",
    landWeather3: "雪",
    landWeather4: "晴れ",
    landGod1: "クリヤティア",
    landGod2: "リャロト",
    landGod3: "オト",
    landGod4: "ラヴラス",
    landGod5: "5",
    landGod6: "6",
    landGod7: "7",
    landGod8: "8",
    landGod9: "9",
    landGod10: "10",
    landGod11: "11",
    landGod12: "12",
    landGod13: "13",
    marketplaceOrders: "マーケットプレイスオファー",
    marketplaceLogTokenType: "トークンタイプ",
    marketplaceLogTokenId: "トークンID",
    marketplaceLogPrice: "価格",
    marketplaceLogStatus: "ステータス",
    marketplaceLogCreatedDate: "作成",
    marketplaceLogActions: "アクション",
    magineko: "マギネコ",
    equipment: "装備",
    active: "アクティブ",
    completed: "完了しました",
    cancelled: "キャンセルしました",
    requestNftDataFailed: "NFTデータの取得に失敗しました",
    confirmOfferCancel: "マーケットプレイスのオファーをキャンセルするには、NFTをウォレットに戻すためのブロックチェーンガス費用として、{0} BNBが必要です。続けますか？",
    cancelOfferRequestSent: "オファーキャンセル依頼が送信されました NFTは数分後にお客様のウォレットに送付されます",
    nftView: "View",
    criptoAnnouncement: "$4Tabernの限定オファーに参加しよう！ 4Tabernは私たち4Tabern Studioガバナンストークンです！",
    misads: "$4Tabern",
    buyMisads: "$4Tabernスペシャルオファー",
    noActiveOffers: "アクティブなキャンペーンはありません",
    forPrice: "For ",
    buyCompleted: "パッケージの購入が完了しました！数分後にお届けします。",
    tokenStoreSendValue: " {0}:を出金します",
    tokenStoreReceiveValue: "{0}:を受け取ります",
    tokenStoreMaxValue: "Your value exceeds the max tokens available (Max: {0})"
}