import { useState, useContext } from "react"
import { ConnectButton, CryptoLogos } from "web3uikit"
import Backdrop from "components/Backdrop"
import { Button, Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import BuyNftModal from "components/BuyNftModal"
import axios from "axios"
import { toast } from "react-toastify"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/style.css'
import { endpointUrl, endpoints } from 'configs/connectionConfig';
import { UserContext } from 'helpers/UserContext';
import SelectLanguage from 'components/SelectLanguage';
import LocalizatedMessage from 'localization';
import { nftTypes } from "nft/nftTypes";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import icon_1 from 'assets/icon_1.png';
import icon_2 from 'assets/icon_2.png';
import icon_3 from 'assets/icon_3.png';
import icon_5 from 'assets/icon_5.png';
import misadLogo from 'assets/misadLogo.png';
import AddTokenButton from "components/AddTokenButton";

const Home = (props) => {
	const [open, setOpen ] = useState(false);

	const navigate = useNavigate();

	const myMisadStakeWindow = document.getElementById('my-misad-stake-window');
	const { userWallet } = useContext(UserContext);

	//Localization
	const myNfts = LocalizatedMessage('myNfts')
	const remnants = LocalizatedMessage('remnants')
	const lands = LocalizatedMessage('lands')
	const souls = LocalizatedMessage('souls')
	const maginekos = LocalizatedMessage('maginekos')
	const equipments = LocalizatedMessage('equipments')
	const myStake = LocalizatedMessage('mystake')
	const myStakeNFTs = LocalizatedMessage('mystakenfts')
	const myStakeMISAD = LocalizatedMessage('mystakemisad')
	const presale = LocalizatedMessage('presale')
	const governance = LocalizatedMessage('governance')
	const myAccount = LocalizatedMessage('myAccount')
	const dashboard = LocalizatedMessage('dashboard')
	const misads = LocalizatedMessage('misads')
	const marketplace = LocalizatedMessage('marketplace')

	const openBuyMaginekoWindow = (e) => {
		e.preventDefault();
		navigate("/buy/maginekos");
	}

	const openBuyRemnantWindow = (e) => {
		e.preventDefault();
		navigate("/buy/remnants");
	}

	const openMyNftWindow = (e) => {
		e.preventDefault();
		navigate("/remnants");
	}

	const openDashboardWindow = (e) => {
		e.preventDefault();
		navigate("/user/dashboard");
	}

	const openMyLandsNftWindow = (e) => {
		e.preventDefault();
		navigate("/lands");
	}

	const openMySoulsNftWindow = (e) => {
		e.preventDefault();
		navigate("/souls");
	}

	const openMyStakeWindow = (e) => {
		e.preventDefault();
		navigate("/stake/nft");
	}

	const openMyMisadStakeWindow = (e) => {
		e.preventDefault();
		navigate("/stake/misad");
	}

	const openMyMaginekosWindow = (e) => {
		e.preventDefault();
		navigate("/maginekos");
	}

	const openMyEquipmentsWindow = (e) => {
		e.preventDefault();
		navigate("/equipments");
	}

	const openBuyMisadsWindow = (e) => {
		e.preventDefault();
		navigate("/");
	}

	const openMarketplaceWindow = (e, entity) => {
		e.preventDefault();
		window.location.href = "/marketplace/" + entity;
	}

	const darkTheme = createTheme({
		palette: {
		  mode: 'dark',
		},
	  });

	  const activeItem = {
		color: "#fff"
	  };
	
	return (
		<>
			<ThemeProvider theme={darkTheme}>
				<Navbar variant="dark" bg="dark" expand="lg">
					<Container>
					    <img className="misadLogo" src={misadLogo} />
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="me-auto">
								<span className="navTitle"><img className="navImg" src={icon_1} />
									<NavDropdown title={presale} id="basic-nav-dropdown">
										<NavDropdown.Item style={(props.label == "buy-criptos" ? activeItem : null)} href={""} onClick={(e) => openBuyMisadsWindow(e)}>{misads}</NavDropdown.Item>
										<NavDropdown.Item style={(props.label == "buy-magineko" ? activeItem : null)} href={"/buy/maginekos"} onClick={(e) => openBuyMaginekoWindow(e)}>{maginekos}</NavDropdown.Item>
										<NavDropdown.Item style={(props.label == "buy-remnant" ? activeItem : null)} href={"/buy/remnants"} onClick={(e) => openBuyRemnantWindow(e)}>{remnants}</NavDropdown.Item>
									</NavDropdown>
								</span>
								
								<span className="navTitle"><img className="navImg" src={icon_2} />
									<NavDropdown title={myAccount} id="basic-nav-dropdown">
										<NavDropdown.Item style={(props.label == "user-dashboard" ? activeItem : null)} href={"/user/dashboard"} onClick={(e) => openDashboardWindow(e)}>{dashboard}</NavDropdown.Item>
										<NavDropdown.Item style={(props.label == "remnant-list" ? activeItem : null)} href={"/remnants"} onClick={(e) => openMyNftWindow(e)}>{remnants}</NavDropdown.Item>
										<NavDropdown.Item style={(props.label == "magineko-list" ? activeItem : null)} href={"/maginekos"} onClick={(e) => openMyMaginekosWindow(e)}>{maginekos}</NavDropdown.Item>
										<NavDropdown.Item style={(props.label == "land-list" ? activeItem : null)} href={"/lands"} onClick={(e) => openMyLandsNftWindow(e)}>{lands}</NavDropdown.Item>
										<NavDropdown.Item style={(props.label == "soul-list" ? activeItem : null)} href={"/souls"} onClick={(e) => openMySoulsNftWindow(e)}>{souls}</NavDropdown.Item>
										<NavDropdown.Item style={(props.label == "equipment-list" ? activeItem : null)} href={"/equipments"} onClick={(e) => openMyEquipmentsWindow(e)}>{equipments}</NavDropdown.Item>
									</NavDropdown>
								</span>
								<span className="navTitle"><img className="navImg" src={icon_3} />
									<NavDropdown title={myStake} id="basic-nav-dropdown">
										<NavDropdown.Item style={(props.label == "nft-stake" ? activeItem : null)} href={"/stake/nft"} onClick={(e) => openMyStakeWindow(e)}>{myStakeNFTs}</NavDropdown.Item>
										<NavDropdown.Item style={(props.label == "misad-stake" ? activeItem : null)} href={"/stake/misad"} onClick={(e) => openMyMisadStakeWindow(e)}>{myStakeMISAD}</NavDropdown.Item>
									</NavDropdown>
								</span>
								<span className="deactivate navTitle"><img className="navImg" src={icon_5} /><Nav.Link href={"#"}>{governance}</Nav.Link></span>
							</Nav>
						</Navbar.Collapse>
						<Navbar.Collapse className="justify-content-end">
							<Navbar.Text>
								<AddTokenButton />
							</Navbar.Text>
							<Navbar.Text>
								<ConnectButton />
							</Navbar.Text>
						</Navbar.Collapse>
						<Navbar.Brand><SelectLanguage /></Navbar.Brand>
					</Container>
				</Navbar>
			</ThemeProvider>
			
			<Backdrop loading={false} />
			
			{open && <BuyNftModal open={open} setOpen={() => setOpen(false)} price={'1500'} />}

			<div class="window-container">
				<div id="buy-nft-window" class={props.contentClass}>
					{props.content}
				</div> 
			</div>
		</>
	)
}

export default Home
