export const messagesInPortuguese = {
    buyNft: "Comprar NFT",
    myNfts: "Meus NFTs",
    buyNftMessage: "Comprar um Remnant",
    buyNftButton: "Comprar NFT",
    nftPrice: "Por {0} ${1}",
    myNftTitle: "Meus NFTs Remnants",
    purchaseNft: "Compra efetuada",
    purchaseButton: "COMPRAR",
    approveSuccessful: "Aprovado com sucesso",
    transactionFailed: "Falha na transação, isso pode ser devido a fundos insuficientes",
    approve: "APROVAR",
    connectWallet: "CONECTAR CARTEIRA",
    buyNftPrice: "COMPRAR NFT POR {0} {1}",
    authorizationFailed: "Falha na autorização",
    walletRequired: "É obrigatório conectar uma carteira",
    failedLoadNfts: "Falha ao carregar os NFTs da conta",
    connectWebservice: "Conectar com o Wing WebService",
    loading: "Carregando...",
    imageLoadingFailed: "Falha ao carregar a imagem do NFT",
    accountNftLoadingFailed: "Falha ao carregar NFTs da conta",
    nftBuySuccess: "Parabéns, aqui está seu NFT!",
    errorOpenNft: "Erro ao abrir NFT",
    nextNftFailed: "Você não tem mais NFTs",
    prevNftFailed: "Você não tem mais NFTs anteriores",
    showingNft: "Mostrando NFT {0} de {1}",
    previous: "Anterior",
    next: "Próximo",
    skill: "Habilidade",
    affinity: "Afinidade",
    willpower: "Sabedoria",
    heredity: "Hereditariedade",
    personality: "Personalidade",
    heart: "Coração",
    noNfts: "Você ainda não tem nenhum Remnant",
    noLandsNfts: "Você ainda não tem nenhum Terreno",
    noSoulsNfts: "Você ainda não tem nenhuma Alma",
    remnants: "Remnants",
    lands: "Terrenos",
    souls: "Almas",
    common: "Comum",
    uncommon: "Incomum",
    rare: "Raro",
    divine: "Divino",
    remnant0type0: "Remnant de Oto (luz)",
    remnant0type1: "Remnant de Lyaloto (ar)",
    remnant0type2: "Remnant de Arunof (agua)",
    remnant1type0: "Remnant de Kyurin (bestial)",
    remnant1type1: "Remnant de Arkin (escuridão)",
    remnant1type2: "Remnant de Ravlas (luz)",
    remnant2type0: "Remnant de Elae (ar)",
    remnant2type1: "Remnant de Praun (fogo)",
    remnant2type2: "Remnant de Opus (escuridão)",
    remnant3type0: "Remnant de Criatia (luz)",
    remnant3type1: "Remnant de Aigui (escuridão)",
    remnant3type2: "Remnant de Iamai (fogo)",
    myLands: "Meus terrenoss",
    mySouls: "Minhas almas",
    bless: "Benção",
    weather: "Clima",
    god: "Divindade",
    stake: "Enviar ao Stake",
    removeStake: "Remover do Stake",
    stakeSent: "NFT enviado ao Stake",
    mystake: "Stake",
    noStakeNfts: "Nenhum NFT no Stake",
    rentability: "Rendendo {0} MISAD/dia",
    stakeRemoved: "NFT enviado para remoção do Stake",
    webservicefailed: "Não foi possível autenticar com WebService, tente recarregar a página",
    webservicedown: "Não foi possível conectar com o WebService, tente novamente mais tarde",
    mystakenfts: "NFTs",
    mystakemisad: "MISAD",
    stakeFailed: "Falha ao carregar dados do Stake",
    stakeValue: "Total em stake: {0} MISAD",
    stakeLastPercentage: "Última rentabilidade {0}",
    reload: "Recarregar",
    depositStake: "Depositar",
    withdrawStake: "Sacar",
    invalidValue: "Valor inválido",
    stakeSuccess: "Valor enviado ao stake, atualize a página em breve",
    stakeWithdrawSuccess: "Pedido de saque enviado",
    transactionInProgress: "Já existe uma transação em movimento",
    valuePlaceholder: "Valor aqui (ex: 0.01)",
    stakeLogMovement: "Movimento",
    stakeLogOldValue: "Valor antigo",
    stakeLogNewValue: "Novo valor",
    stakeLogDescription: "Descrição",
    stakeLogFailed: "Falha ao consultar o histórico do Stake",
    stakeLogPrev: "Página anterior",
    stakeLogNext: "Próxima página",
    stakeLogPage: "Página {0} de {1}",
    nftDetails: "Detalhes",
    nftOpen: "Abrir",
    remnant: "Remnant",
    land: "Terreno",
    soul: "Alma",
    ok: "Okay",
    cancel: "Cancelar",
    withdrawMessage: "Para requisitar uma retirada é necessário enviar um valor de {0} BNB para lidar com o Gas Cost da Blockchain. Deseja continuar?",
    nftTypesButton: "?",
    actualStake: "Rendimento total: {0} MISAD",
    baseAPR: "APR atual: {0}%",
    pageable: "Página {0} de {1}",
    purchaseFailed: "Falha na transação, isso pode ser devido a fundos insuficientes",
    deposit: "Depósito",
    withdraw: "Saque",
    awaiting: "Aguardando",
    success: "Sucesso",
    failure: "Falha",
    error: "Erro",
    fraud: "Fraude",
    confirmed: "Confirmada",
    value: "Valor",
    status: "Status",
    myremnants: "Meus Remnants",
    buyWith: "Comprar usando:",
    lockStake: "Para poder adicionar MISAD ao seu stake, primeiro é necessário selecionar um período de trava. Durante este período, você não poderá retirar seus MISADs:",
    changeLockStake: "Selecione um novo período de trava começando de {0}:",
    lock1Month: "1 Meses",
    lock3Months: "3 Meses",
    lock6Months: "6 Meses",
    lock12Months: "12 Meses",
    maxApr: "Máximo de APR: {0}%",
    changeLockButton: "Estender período de trava",
    lockDate: "Travado até {0}",
    lockUpdated: "Data de trava atualizada!",
    restake: "Restake",
    restakeConfirm: " Uma taxa de {0}% de MISADs (Máximo de 0.5 MISAD) será cobrada das suas recompensas ao serem transferidas para o Stake. Deseja continuar?",
    restakeSuccess: "Restake efetuado com sucesso!",
    claim: "Claim",
    claimTransaction: "Saque de rendas",
    claimValue: "Todas as recompensas",
    date: "Data",
    WITHDRAW_REWARD: "Saque de recompensas",
    REWARD_RESTAKE: "Restake de recompensas",
    TAX: "Taxa de APR",
    WITHDRAW: "Saque",
    DEPOSIT: "Depósito",
    cardSetsFailed: "Falha ao receber informação de venda dos NFTs",
    notWhitelisted: "Sua carteira não está permitida na Whitelist para comprar este NFT",
    openNftConfirm: "Deseja realmente abrir este NFT?",
    valuePrompt: "Digite o valor desejado:",
    english: "Inglês",
    portuguese: "Português",
    japanese: "Japonês",
    claimText: "Para onde você deseja enviar suas recompensas?",
    claimPrimaryButton: "Minha carteira",
    claimSecondaryButton: "Meu stake",
    myStake: "Meu Stake",
    stakedValue: "Valor em Stake",
    stakedNFTs: "NFTs em Stake",
    dailyRewards: "Recompensas diárias",
    nftStakeStats: "Estatísticas do Stake NFT",
    totalSupply: "Fornecimento Total",
    nftStats: "Estatísticas de NFT",
    total: "Total",
    apr: "APR",
    maxApr: "MAX APR",
    upgrade: "Aumentar",
    availableRewards: "Recompensas disponíveis",
    stake: "Stake",
    unstake: "Unstake",
    stakeLockTime: "Unstake bloqueado até: {0}",
    flexibleUnstake: "Unstake flexível",
    globalApr: "APR Global de MISAD",
    stats: "Estatísticas de MISAD",
    totalStaked: "TOTAL EM STAKE",
    misadPrice: "Preço do MISAD",
    poweredBy: "Powered by",
    misadChain: "Misad Chain Network",
    transactionLog: "Log de transações",
    remnantAnnouncement: "Participe de ofertas limitadas de Remnants! NFT mais importante de Wing of Misadventure e também seu leal companheiro!",
    remnantReadmore: "Leia mais sobre Remnants",
    nftStakeTitle: "Stake de NFTs",
    purchaseSuccessful: "Compra efetuada, NFT a caminho!",
    myNFTStake: "Meu NFT Stake",
    type: "Tipo",
    totalRewards: "Total de recompensas",
    misadStake: "MISAD Stake",
    unlisted: "Não listado",
    close: "Fechar",
    confirm: "Confirmar",
    lockAPRCheckBoxConfirmation: "I understand that i will not be able to unstake the $MISAD for the selected time",
    stakeValueTip: "You will choose the APR lock date after confirmation",
    stakeValueTip2: "You will not be able to unstake $MISAD until the lock date",
    lockMonths: "{0} Meses",
    maxAprPercentage: "{0}% MAX APR",
    presale: "Pré-venda",
    governance: "Governância",
    maintenance: "Atualmente o Dapp se encontra em manutenção, a expectativa de retorno é de 03/05.",
    download: "Baixar",
    maintenanceFunction: "Esta função se encontra em manutenção no momento, tente novamente mais tarde.",
    copySharedLink: "Copiar link compartilhável",
    linkCopied: "Copiado para a área de transferência",
    misadAdded: "MISAD foi importado em sua carteira!",
    misadError: "Falha ao importar MISAD, verifique sua carteira e plugin do Metamask!",
    importMisad: "Importar $MISAD",
    maginekos: "Maginekos",
    buyMaginekoNftMessage: "Compre um Magineko",
    myMaginekos: "Meus Maginekos",
    noMaginekoNfts: "Você não tem nenhum NFT Magineko",
    equipments: "Equipamentos",
    petMagineko: "Acaricie este Magineko!",
    selectPaymentMethodMessage: "Selecione o método de pagamento:",
    lackOfPets: "Esse Magineko já atingiu seu limite diário",
    petComplete: "Miau! Você acariciou este Magineko! Em breve sua recompensa irá chegar na aba de itens!",
    totalInWallet: "Saldo atual: {0}",
    withdrawToWallet: "Sacar para minha carteira",
    failedLoadingBalance: "Falha ao carregar dados da carteira",
    withdrawWalletSuccess: "Saque de {0} requisitado com sucesso",
    withdrawCostMessage: "In order to request a withdraw, an amount of {0} {1} is required to deal with the blockchain gas cost. Do you want to continue?",
    maginekoReadmore: "Saiba mais sobre Maginekos",
    maginekoAnnouncement: "Participe de ofertas limitadas de Maginekos, os companheiros mais fofos no mundo de Misadventure! Garanta já o acesso ao Closed Beta!",
    maginekoPartBody: "Corpo",
    maginekoPartEye: "Olho",
    maginekoPartAccessory: "Acessório",
    maginekoPartClothing: "Roupa",
    maginekoPartWeapon: "Arma",
    maginekoPartBackground: "Fundo",
    maginekoScore: "Pontuação de raridade: {0}",
    myAccount: "Minha conta",
    dashboard: "Resumo",
    myEquipments: "Meus Equipamentos",
    noEquipmentNfts: "Você não tem nenhum item NFT",
    dailyPettings: "Carícias diárias: ",
    randomMaginekoLabel: "Quer receber novos itens raros/divinos? Tente acariciar um Magineko!",
    randomMaginekoButton: "Encontre um Magineko aleatório!",
    randomMaginekoAnother: "Encontrar outro Magineko",
    misads: "$4Tabern",
    buyMisads: "Ofertas especiais de $4Tabern",
    noActiveOffers: "Nenhuma oferta ativa",
    forPrice: "Por ",
    buyCompleted: "Você comprou este pacote! A entrega deverá ser feita em alguns instantes.",
    tokenStoreSendValue: "Converter {0}:",
    tokenStoreReceiveValue: "Em {0}:",
    randomMaginekoAnother: "Encontrar outro Magineko",
    addMarketplaceOfferPrice: "Preço (em BNB):",
    addMarketplaceOfferAgreement: "Eu compreendo de que 6.5% do valor total da venda será retirada como taxa e de que meu NFT será enviado para a carteira do Wing of Misadventure até que seja vendido ou eu pague uma taxa de retirada para cancelar a venda.",
    addMarketplaceOffer: "Registrar",
    sell: "Vender",
    addMarketplaceOfferSuccess: "Oferta enviada! Será registrada no mercado em breve.",
    marketplace: "Mercado",
    marketplaceFilter: "Filtrar resultados por",
    rarity: "Raridade",
    marketplaceSearch: "Procurar",
    noResults: "Nenhum resultado.",
    buyMarketplaceOffer: "Comprar",
    marketplacePurchaseCompleted: "Compra efetuada! Seu NFT será entregue em breve.",
    showingRecords: "Mostrando {0} de {1} resultados",
    orderBy: "Ordenar por...",
    newest: "Mais novo",
    oldest: "Mais antigo",
    cheapPrice: "Mais barato",
    highestPrice: "Mais caro",
    weapon: "Arma",
    shield: "Escudo",
    gender: "Gênero",
    male: "Masculino",
    female: "Feminino",
    accessory1Male: "Máscara",
    accessory2Male: "Bigode",
    accessory3Male: "Cachimbo",
    accessory4Male: "Tapa-olho",
    outfit1Male: "Casaco Vermelho",
    outfit2Male: "Armadura",
    outfit3Male: "Casaco Branco",
    outfit4Male: "Armadura Negra",
    outfit5Male: "Armadura Branca",
    eyes1Male: "Azuis",
    eyes2Male: "Vermelhos",
    eyes3Male: "Amarelos",
    eyes4Male: "Laranjas",
    eyes5Male: "Verdes",
    hair1Male: "Marrom (Curto)",
    hair2Male: "Marrom Escuro (Curto)",
    hair3Male: "Branco (Curto)",
    hair4Male: "Marrom (Muito Curto)",
    hair5Male: "Loiro Claro (Curto)",
    hair6Male: "Loiro Escuro (Curto)",
    accessory1Female: "Chapéu",
    accessory2Female: "Orelhas Felpudas",
    accessory3Female: "Óculos",
    accessory4Female: "Flor",
    accessory5Female: "Capacete",
    outfit1Female: "Casaco Preto",
    outfit2Female: "Armadura Azul",
    outfit3Female: "Vestido Azul",
    outfit4Female: "Casaco Branco",
    outfit5Female: "Armadura Preta",
    eyes1Female: "Marrons",
    eyes2Female: "Azuis",
    eyes3Female: "Vermelhos",
    eyes4Female: "Laranjas",
    eyes5Female: "Verdes",
    hair1Female: "Azul (Longo)",
    hair2Female: "Marrom (Curto)",
    hair3Female: "Branco (Longo)",
    hair4Female: "Castanho (Longo)",
    hair5Female: "Loiro (Longo)",
    none: "Nenhum",
    accessory: "Acessório",
    outfit: "Roupa",
    eyes: "Olhos",
    hair: "Cabelos",
    maginekoBackground1: "Templo",
    maginekoBackground2: "Castelo",
    maginekoBackground3: "Gatosfera",
    maginekoBackground4: "Navio",
    maginekoMaleHat1: "Chapéu de Pirata",
    maginekoMaleHat2: "Chapéu com Pena Azul",
    maginekoMaleHat3: "Chapéu com Pena Amarela",
    maginekoMaleHat4: "Cartola Vermelha",
    maginekoMaleHat5: "Cartola Amarela",
    maginekoMaleHat6: "Cartola Azul",
    maginekoMaleHat7: "Capacete Samurai Azul",
    maginekoMaleHat8: "Capacete Samurai Vermelho",
    maginekoMaleHat9: "Boina",
    maginekoMaleHat10: "Chapéu de Palha",
    maginekoMaleFur1: "Marrom",
    maginekoMaleFur2: "Siamês",
    maginekoMaleFur3: "Tigrado",
    maginekoMaleFur4: "Branco",
    maginekoMaleFur5: "Marrom & Branco",
    maginekoEyes1: "Azuis",
    maginekoEyes2: "Laranjas",
    maginekoEyes3: "Amarelos",
    maginekoEyes4: "Roxos",
    maginekoEyes5: "Verdes",
    maginekoMaleOutfit1: "Roupas de Arqueiro",
    maginekoMaleOutfit2: "Casaco Vermelho",
    maginekoMaleOutfit3: "Casaco Amarelo",
    maginekoMaleOutfit4: "Casaco Azul",
    maginekoMaleOutfit5: "Armadura Azul",
    maginekoMaleOutfit6: "Armadura Amarela",
    maginekoMaleOutfit7: "Armadura Samurai Vermelha",
    maginekoMaleOutfit8: "Armadura Samurai Azul",
    maginekoMaleOutfit9: "Roupas de Pirata",
    maginekoMaleOutfit10: "Quimono da 4Tabern",
    maginekoMaleOutfit11: "Quimono Vermelho",
    maginekoMaleOutfit12: "Quimono Amarelo",
    maginekoMaleOutfit13: "Quimono Roxo",
    maginekoMaleWeapon1: "Espada",
    maginekoMaleWeapon2: "Pistola",
    maginekoMaleWeapon3: "Arco",
    maginekoMaleWeapon4: "Cajado",
    maginekoMaleWeapon5: "Katana",
    maginekoMaleWeapon6: "Machado",
    maginekoMaleWeapon7: "Adaga",
    maginekoFemaleHat1: "Chapéu Vermelho",
    maginekoFemaleHat2: "Chapéu Verde",
    maginekoFemaleHat3: "Monóculo",
    maginekoFemaleHat4: "Chapéu de Pirata",
    maginekoFemaleHat5: "Tiara",
    maginekoFemaleHat6: "Capacete Samurai",
    maginekoFemaleHat7: "Máscara Vermelha",
    maginekoFemaleHat8: "Máscara Azul",
    maginekoFemaleFur1: "Marrom",
    maginekoFemaleFur2: "Tricolor",
    maginekoFemaleFur3: "Tigrado",
    maginekoFemaleFur4: "Branco",
    maginekoFemaleFur5: "Preto",
    maginekoFemaleEyes1: "Vermelhos",
    maginekoFemaleEyes2: "Azuis",
    maginekoFemaleEyes3: "Amarelos",
    maginekoFemaleEyes4: "Rosas",
    maginekoFemaleEyes5: "Verdes",
    maginekoFemaleOutfit1: "Roupas de Pirata",
    maginekoFemaleOutfit2: "Armadura Vermelha",
    maginekoFemaleOutfit3: "Armadura Azul",
    maginekoFemaleOutfit4: "Vestido Vermelho",
    maginekoFemaleOutfit5: "Vestido Roxo",
    maginekoFemaleOutfit6: "Vestido Branco",
    maginekoFemaleOutfit7: "Armadura Samurai Azul",
    maginekoFemaleOutfit8: "Armadura Samurai Vermelha",
    maginekoFemaleOutfit9: "Armadura Samurai Amarela",
    maginekoFemaleOutfit10: "Quimono 4Tabern",
    maginekoFemaleOutfit11: "Vestido Laranja",
    maginekoFemaleOutfit12: "Vestido Marrom",
    maginekoFemaleOutfit13: "Vestido Azul",
    maginekoFemaleWeapon1: "Espada",
    maginekoFemaleWeapon2: "Cajado Laranja",
    maginekoFemaleWeapon3: "Cajado Roxo",
    maginekoFemaleWeapon4: "Cajado da Natureza",
    maginekoFemaleWeapon5: "Arco",
    maginekoFemaleWeapon6: "Pistola",
    maginekoFemaleWeapon7: "Katana",
    maginekoFemaleWeapon8: "Adaga",
    maginekoFemaleWeapon9: "Machado",
    landType1: "Castelo",
    landType2: "Cidade",
    landType3: "Floresta",
    landType4: "Vilarejo",
    landBless1: "Agilidade",
    landBless2: "Inteligência",
    landBless3: "Força",
    landBless4: "Sabedoria",
    landWeather1: "Nebuloso",
    landWeather2: "Chuvoso",
    landWeather3: "Neve",
    landWeather4: "Ensolarado",
    landGod1: "Criatia",
    landGod2: "Lyaloto",
    landGod3: "Oto",
    landGod4: "Ravlas",
    landGod5: "5",
    landGod6: "6",
    landGod7: "7",
    landGod8: "8",
    landGod9: "9",
    landGod10: "10",
    landGod11: "11",
    landGod12: "12",
    landGod13: "13",
    marketplaceOrders: "Ofertas no mercado",
    marketplaceLogTokenType: "Tipo do Token",
    marketplaceLogTokenId: "Token ID",
    marketplaceLogPrice: "Preço",
    marketplaceLogStatus: "Status",
    marketplaceLogCreatedDate: "Criado em",
    marketplaceLogActions: "Ações",
    magineko: "Magineko",
    equipment: "Equipamento",
    active: "Ativo",
    completed: "Completo",
    cancelled: "Cancelado",
    requestNftDataFailed: "Falha ao receber dados do NFT",
    confirmOfferCancel: "Para que possa cancelar uma oferta do mercado, uma taxa de {0} BNB é necessária para arcar com os custos da transação da blockchain para enviar o NFT de volta á sua carteira. Deseja continuar?",
    cancelOfferRequestSent: "Requisição de cancelamento enviada! Seu NFT deverá voltar á sua carteira em breve.",
    nftView: "Visualizar",
    criptoAnnouncement: "Participe de ofertas limitadas de $4Tabern! O Token de governância da 4Tabern!",
    tokenStoreMaxValue: "Este valor excede o máximo de tokens disponíveis (Máximo: {0})"
}
