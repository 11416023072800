import { useMoralis } from 'react-moralis'
import { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route
} from 'react-router-dom'
import Theme from './configs/theme'
import { UserContext } from 'helpers/UserContext'
import { contractAddressByType } from 'configs/contractAddress'
import Home from 'pages/Home'
import { chainNet } from 'configs/buyNftConfig'
import NFTList from 'pages/NFTList'
import { nftTypes } from 'nft/nftTypes'
import { endpointUrl, endpoints } from 'configs/connectionConfig';
import axios, { Axios } from "axios"
import LandNFTList from 'pages/LandNFTList'
import SoulsNFTList from 'pages/SoulsNFTList'
import StakeNFTList from 'pages/StakeNFTList'
import MisadStake from 'pages/MisadStake'
import LocalizatedMessage from 'localization'
import { toast } from "react-toastify"
import Maintenance from 'components/Maintenance'
import NFTView from 'pages/NFTView'
import LandNFTView from 'pages/LandNFTView'
import SoulNFTView from 'pages/SoulNFTView'
import BuyRemnants from 'pages/BuyNfts/BuyRemnants'
import BuyMaginekos from 'pages/BuyNfts/BuyMaginekos'
import MaginekoNFTList from 'pages/MaginekoNFTList'
import MaginekoNFTView from 'pages/MaginekoNFTView'
import EquipmentNFTList from 'pages/EquipmentNFTList'
import EquipmentNFTView from 'pages/EquipmentNFTView'
import UserDashboard from 'pages/UserDashboard'
import BuyMISAD from 'pages/BuyMISAD'
import Marketplace from 'pages/Marketplace'

//import ReactGA from 'react-ga';
//const TRACKING_ID = "UA-217305639-1"; // OUR_TRACKING_ID
//ReactGA.initialize(TRACKING_ID);

const App = () => {
  const { Moralis, isAuthenticated, account } = useMoralis();

  //const location = window.location.pathname;
  const [userData, setUserData] = useState({
    userWallet: null,
    userAllowance: {
      bnb: 0,
      misad: 0,
    },
    refetch: true,
    tokenAuth: null,
    tokenCreated: null,
    tokenExpires: null
  })

  //Localization
  const authorizationFailed = LocalizatedMessage('authorizationFailed');

  let updateTokenInterval = null;

  useEffect(() => {
    if (userData.tokenAuth && userData.userWallet) 
    {
      if (!updateTokenInterval) {
        clearTimeout(updateTokenInterval);
      }

      var createdDate = new Date(userData.tokenCreated)
      var expiresDate = new Date(userData.tokenExpires)
      var expireInterval = expiresDate.getTime() - createdDate.getTime();

      updateTokenInterval = setTimeout(() => {
        handleAuthToken(userData.userWallet)
      }, expireInterval)
    }
  }, [userData.tokenAuth])

  useEffect(() => {
    if (isAuthenticated) {
      if (account != null) {
        //MOCK: (MOCK WALLETS HERE IF YOU WANT)
        //handleAuthToken(account);
      }else{
        setUserData({
          ...userData,
          userWallet: account,
          refetch: !userData.refetch
        })
      }
    }else{
      setUserData({
        ...userData,
        tokenAuth: null,
        userWallet: null,
        refetch: !userData.refetch
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, account])

  const handleAuthToken = async (userWallet) => {
      try {
          const endpoint = endpointUrl + endpoints['login'];
          const response = await axios.post(endpoint, 
              {
                  Account: userWallet
              }
          );
          setUserData({
            ...userData,
            tokenAuth: response.data.token,
            tokenCreated: response.data.created,
            tokenExpires: response.data.expires,
            userWallet: account,
            refetch: !userData.refetch
          })
      } catch (error) {
          toast.error(authorizationFailed) 
      }
  }

  String.prototype.format = function () {
		var args = arguments;
		return this.replace(/{([0-9]+)}/g, function (match, index) {
		  return typeof args[index] == 'undefined' ? match : args[index];
		});
	  };

  return (
    <UserContext.Provider value={userData}>
      <Router>
        <Theme>
          <Switch>
            <Route path='/buy/maginekos' element={<Home label="buy-magineko" contentClass="window-box" content={<Maintenance />} />} />
            <Route path='/buy/remnants' element={<Home label="buy-remnants" contentClass="window-box" content={<Maintenance />} />} />
            <Route path='/remnants' element={<Home label="remnant-list" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/lands' element={<Home label="land-list" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/souls' element={<Home label="soul-list" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/stake/nft' element={<Home label="nft-stake" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/stake/misad' element={<Home label="misad-stake" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/remnant/:id' element={<Home label="remnant-view" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/land/:id' element={<Home label="land-view" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/soul/:id' element={<Home label="soul-view" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/maginekos' element={<Home label="magineko-list" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/magineko/:id' element={<Home label="magineko-view" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/equipments' element={<Home label="equipment-list" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/equipment/:id' element={<Home label="equipment-view" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='/user/dashboard' element={<Home label="user-dashboard" contentClass="window-box" content={<Maintenance />}></Home>}/>
            <Route path='' element={<Home label="buy-criptos" contentClass="window-box" content={<Maintenance />}></Home>}/>
          </Switch>
        </Theme>
      </Router>
    </UserContext.Provider>
  )
}

export default App
