let busdAddress = process.env.REACT_APP_BUSD_ADDRESS;
let misadAddress = process.env.REACT_APP_MISAD_ADDRESS;
let remnantAddress = process.env.REACT_APP_REMNANT_ADDRESS;
let landAddress = process.env.REACT_APP_LAND_ADDRESS;
let ethereumBUSDAddress = process.env.REACT_APP_ETHEREUM_BUSD_ADDRESS;
let maginekoAddress = process.env.REACT_APP_MAGINEKO_ADDRESS;
let usdtAddress = process.env.REACT_APP_USDT_ADDRESS;
let soulAddress = process.env.REACT_APP_SOUL_ADDRESS;
let equipmentAddress = process.env.REACT_APP_EQUIPMENT_ADDRESS;
let klayUsdtAddress = process.env.REACT_APP_KLAY_USDT_ADDRESS;

export const contractAddressByType = {
    BUSD: busdAddress,
    MISAD: misadAddress,
    MISADNft: remnantAddress,
    MISADLandNft: landAddress,
    ETHEREUM_BUSD: ethereumBUSDAddress,
    MAGINEKO: maginekoAddress,
    USDT: usdtAddress,
    SOUL: soulAddress,
    EQUIPMENT: equipmentAddress,
    KLAY_USDT: klayUsdtAddress
};

export const currencyName = {
    BUSD: 'BUSD',
    MISAD: 'MISAD',
    ETHEREUM_BUSD: 'USDT',
    USDT: 'USDT',
    KLAY_USDT: "USDT",
    TABERN: '$4TABERN'
};

export const currencySymbol = 'MISAD';

export function getContractAddressByEntity(entity) {
    switch (entity.toLowerCase()) {
        case "remnant":
            return contractAddressByType['MISADNft'];
        case "land":
            return contractAddressByType['MISADLandNft'];
        case "soul":
            return contractAddressByType['SOUL'];
        case "magineko":
            return contractAddressByType['MAGINEKO'];
        case "equipment":
            return contractAddressByType['EQUIPMENT'];  
    }
}