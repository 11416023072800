import { useMoralis } from "react-moralis";
import { ImportToken, MoralisHandleChain } from "helpers/moralisHelper";
import React, { useContext, useEffect, useState } from 'react';
import LocalizatedMessage from "localization";
import { UserContext } from 'helpers/UserContext';
import { Button } from 'react-bootstrap';
import { toast } from "react-toastify";

const AddTokenButton = () => {
    const { Moralis } = useMoralis();
    const { userWallet } = useContext(UserContext)

    const [openRequest, setOpenRequest] = useState(false);

    const importMisad = LocalizatedMessage('importMisad')
    const misadAdded = LocalizatedMessage('misadAdded');
    const misadError = LocalizatedMessage('misadError');

    useEffect(async () => {
        if (openRequest) {
            MoralisHandleChain(Moralis, async () => {
                try {
                    let added = await ImportToken();

                    if (added) {
                        toast.success(misadAdded)
                    }
                }catch (e) {
                    if (e.code != 4001) {
                        toast.error(misadError)
                    }
                }
            });

            setOpenRequest(false)
        }
    }, [openRequest])

    const onClick = async () => {
        setOpenRequest(true);
    }

    return (<>
        {userWallet && <div class="soft"><Button onClick={() => onClick()}>{importMisad}</Button></div>}
    </>)
}

export default AddTokenButton